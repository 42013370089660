.main-container {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-color: #f3f7fb;
	display: flex;
	box-sizing: border-box;
}

.content {
	flex: 1;
	background-color: #f3f7fb;
	height: 100vh;
	min-height: 500px !important;
	height: 100vh;
	overflow: auto;
}

.sidebar-bg {
	width: 100%;
	min-height: 500px;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.514) !important;
	position: absolute;
	z-index: 5;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;
}

.hide {
	display: none;
}

.sidebar {
	width: 20vw;
	height: 100vh;
  min-height: 502px;
  background-color: #17242A;
	/* background-color: #fff; */
	/* border-right: 2px solid #e3eaf2; */
  z-index: 10;
  overflow: hidden;
}

.hidden {
  display: none;
	margin-left: -240px;
}

.mobile {
	position: absolute !important;
	margin-left: -2px !important;
	margin-top: -2px !important;
}

.toggled {
	margin-left: -240px;
}

.header {
	padding: 10px 20px;
	height: 40px;
}

.toggle-icon {
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	transform: rotate(90deg) !important;
	color: rgb(63, 63, 63);
	width: 30px;
	height: 30px;
	font-size: 25px !important;
	float: right;
	cursor: pointer;
	margin-right: -10px;
}

.toggle-icon:hover {
	letter-spacing: 2px;
	text-align: center;
	margin-top: -5px;
}

.sidebar .logo {
	height: 80px;
	display: block;
  margin: 50px auto;
  background: red;
}

.sidebar .logo img{
  width: 60%;
  height: 100%;
  position: relative;
  border-radius: 50%;
}

.sidebar .icons {
	width: 16px;
	height: 16px;
	display: inline-block;
	margin-top: -5px;
	margin-left: 23px;
	margin-right: 10px;
}

.sidebar a {
	display: block;
	padding-left: 10px;
	/* padding: 10px !important; */
	/* padding: 0 !important; */
	/* color: #5f819f !important; */
	text-decoration: none !important;
	font-weight: 400;
	/* margin-top: 15px; */
}

.sidebar-divider {
	margin: 22px;
	border-width: 2px;
}

.admin-section-text {
	margin-left: 23px;
	font-weight: 500;
	font-size: 12px;
	color: rgb(197, 197, 197);
}

.inherit {
	position: inherit;
	bottom: 0;
	width: 240px;
	left: 0;
}

.absolute {
	position: absolute;
	bottom: 0;
	width: 240px;
	left: 0;
}
.width-150 {
	width: 150px;
}
.font-weight-600 {
	font-weight: 600 !important;
}
.active-nav {
	background-color: #5f819f !important;
	color: #fff !important;
	padding: 10px 15px !important;
}
.notActive-nav {
	background-color: #fff !important;
	color: #5f819f !important;
	padding: 12px 15px !important;
}
.height-60 {
	height: 60px;
}

.admin-btn-logout {
	background-color: #74BDD9 !important;
}

.nav-link{
  height: 8vh;
}

.tabContent {
  border-top: 15px #17242A;
  border-top-width: medium;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.sidebar hr{
  background:#fff;
  padding:0
}

.sidebar .heading{
  background:#fff;
}

.sidebar .heading .profile{
  /* height: 30vh; */
  background: #fff;
  border-bottom:2px solid rgb(197, 197, 197);
}

.sidebar .heading .profile img{
  width: 60%;
  height: 60%;
  border-radius: 50%;
  background:red
}

.sidebar .heading .profile .details{
  color:#17242A;
  text-align: center;
}

.sidebar .sidebar-links{
  width: 100%;
  padding:0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

.sidebar .sidebar-links li{
  width: 95.5%;
  text-indent: 10%;
  color:#fff;
}

.sidebar .sidebar-links li.active{
  background: #fff;
  color:#17242A;
  border-right:5px solid orange;
}

.sidebar .sidebar-links li a{
  padding: 15px;
  color:#fff;
  display: inline-flex;
  grid-template-columns: 1fr auto;
  align-items: center;
  cursor: pointer;
}

.sidebar .sidebar-links li.active a{
  color:#17242A;
}

.sidebar .sidebar-links li a i{
  margin-right: 15px;
}

.sidebar .sidebar-links li:hover{
  background: #fff;
  color:#17242A;
  border-right:5px solid orange;
}

.sidebar .sidebar-links li:hover a{
  color:#17242A;
}

.sidebar .footer{
  color:#fff;
  margin-top: 25vh;
  text-align: center;
}

.sidebar .footer p{
  padding-top: 30%;
}