.landing{
  margin: 0;
  padding: 0;
  overflow: auto;
  overflow-x: hidden;
  background: #ffffff;
}

.landing .map{
  position: relative;
  margin:0;
  padding:0;
  width: 100%;
  height: 100vh;
}

.landing .h-300{
  height: 32.5vh;
}