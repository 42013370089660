@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@200;300;500;600&display=swap);
/* @import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@200;300;500;600&display=swap); */

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", "Barlow Condensed", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #3AAFA9 !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.card-large{
  height: 140px;
  color:#fff;
}

.text-huge{
  font-size: 3.5em;
}

.float{
	position:fixed;
	width:8vw;
	height:40px;
	bottom:40px;
	left:40px;
	background-color:#0C9;
	color:#FFF;
	border-radius:15px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
  margin-top:7.5px;
}

.scrollable-table{
  height: 350px;
  overflow: auto;
  overflow-y: scroll;
}
.main-container {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-color: #f3f7fb;
	display: flex;
	box-sizing: border-box;
}

.content {
	flex: 1 1;
	background-color: #f3f7fb;
	height: 100vh;
	min-height: 500px !important;
	height: 100vh;
	overflow: auto;
}

.sidebar-bg {
	width: 100%;
	min-height: 500px;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.514) !important;
	position: absolute;
	z-index: 5;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	overflow: hidden;
}

.hide {
	display: none;
}

.sidebar {
	width: 20vw;
	height: 100vh;
  min-height: 502px;
  background-color: #17242A;
	/* background-color: #fff; */
	/* border-right: 2px solid #e3eaf2; */
  z-index: 10;
  overflow: hidden;
}

.hidden {
  display: none;
	margin-left: -240px;
}

.mobile {
	position: absolute !important;
	margin-left: -2px !important;
	margin-top: -2px !important;
}

.toggled {
	margin-left: -240px;
}

.header {
	padding: 10px 20px;
	height: 40px;
}

.toggle-icon {
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
	transform: rotate(90deg) !important;
	color: rgb(63, 63, 63);
	width: 30px;
	height: 30px;
	font-size: 25px !important;
	float: right;
	cursor: pointer;
	margin-right: -10px;
}

.toggle-icon:hover {
	letter-spacing: 2px;
	text-align: center;
	margin-top: -5px;
}

.sidebar .logo {
	height: 80px;
	display: block;
  margin: 50px auto;
  background: red;
}

.sidebar .logo img{
  width: 60%;
  height: 100%;
  position: relative;
  border-radius: 50%;
}

.sidebar .icons {
	width: 16px;
	height: 16px;
	display: inline-block;
	margin-top: -5px;
	margin-left: 23px;
	margin-right: 10px;
}

.sidebar a {
	display: block;
	padding-left: 10px;
	/* padding: 10px !important; */
	/* padding: 0 !important; */
	/* color: #5f819f !important; */
	text-decoration: none !important;
	font-weight: 400;
	/* margin-top: 15px; */
}

.sidebar-divider {
	margin: 22px;
	border-width: 2px;
}

.admin-section-text {
	margin-left: 23px;
	font-weight: 500;
	font-size: 12px;
	color: rgb(197, 197, 197);
}

.inherit {
	position: inherit;
	bottom: 0;
	width: 240px;
	left: 0;
}

.absolute {
	position: absolute;
	bottom: 0;
	width: 240px;
	left: 0;
}
.width-150 {
	width: 150px;
}
.font-weight-600 {
	font-weight: 600 !important;
}
.active-nav {
	background-color: #5f819f !important;
	color: #fff !important;
	padding: 10px 15px !important;
}
.notActive-nav {
	background-color: #fff !important;
	color: #5f819f !important;
	padding: 12px 15px !important;
}
.height-60 {
	height: 60px;
}

.admin-btn-logout {
	background-color: #74BDD9 !important;
}

.nav-link{
  height: 8vh;
}

.tabContent {
  border-top: 15px #17242A;
  border-top-width: medium;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.sidebar hr{
  background:#fff;
  padding:0
}

.sidebar .heading{
  background:#fff;
}

.sidebar .heading .profile{
  /* height: 30vh; */
  background: #fff;
  border-bottom:2px solid rgb(197, 197, 197);
}

.sidebar .heading .profile img{
  width: 60%;
  height: 60%;
  border-radius: 50%;
  background:red
}

.sidebar .heading .profile .details{
  color:#17242A;
  text-align: center;
}

.sidebar .sidebar-links{
  width: 100%;
  padding:0;
  margin: 0;
  text-decoration: none;
  list-style: none;
}

.sidebar .sidebar-links li{
  width: 95.5%;
  text-indent: 10%;
  color:#fff;
}

.sidebar .sidebar-links li.active{
  background: #fff;
  color:#17242A;
  border-right:5px solid orange;
}

.sidebar .sidebar-links li a{
  padding: 15px;
  color:#fff;
  display: inline-flex;
  grid-template-columns: 1fr auto;
  align-items: center;
  cursor: pointer;
}

.sidebar .sidebar-links li.active a{
  color:#17242A;
}

.sidebar .sidebar-links li a i{
  margin-right: 15px;
}

.sidebar .sidebar-links li:hover{
  background: #fff;
  color:#17242A;
  border-right:5px solid orange;
}

.sidebar .sidebar-links li:hover a{
  color:#17242A;
}

.sidebar .footer{
  color:#fff;
  margin-top: 25vh;
  text-align: center;
}

.sidebar .footer p{
  padding-top: 30%;
}
.horizontal-scroll {
	width: 1300px;
	overflow-x: scroll;
	scrollbar-color: transparent;
}
.avatar-icon {
	height: 40px;
}
.avatar-icon-100 {
	height: 100px;
}
.border-black {
	border: 1px solid #000;
}
.height-150 {
	height: 150px;
}
.border-top-black {
	border-top: 1px solid #000;
}
.height-100 {
	height: 100px;
}
.overflow-scroll {
	overflow: auto;
}
.color-black {
	color: #000;
}
.display-none {
	display: none;
}
.hand {
	cursor: pointer;
}
.public-DraftStyleDefault-block {
	margin: 0 !important;
}
.rdw-emoji-modal {
	overflow: auto;
	position: absolute;
	top: 35px;
	left: -140px !important;
	right: 0px !important;
	display: flex;
	flex-wrap: wrap;
	width: 210px !important;
	height: 180px;
	border: 1px solid #f1f1f1;
	padding: 15px 0 15px 15px;
	border-radius: 2px;
	z-index: 100;
	background: white;
	box-shadow: 3px 3px 5px #bfbdbd;
}
::-webkit-scrollbar {
	width: 0;
	background: transparent;
}
.overflow-hidden {
	overflow: hidden;
}
.bg-green {
	background: #53af31 !important;
}
.width-120 {
	width: 120px;
}

.height-35 {
	height: 35px !important;
}
.bg-dblue {
	background: #5f819f !important;
}
.to-uppercase {
	text-transform: uppercase;
}
.w-100 {
	width: 100% !important;
}

.landing{
  margin: 0;
  padding: 0;
  overflow: auto;
  overflow-x: hidden;
  background: #ffffff;
}

.landing .map{
  position: relative;
  margin:0;
  padding:0;
  width: 100%;
  height: 100vh;
}

.landing .h-300{
  height: 32.5vh;
}
.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  -webkit-animation: pulsate 1s ease-out;
          animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #00cae9;
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
.v2-container {
	font-family: "Barlow Condensed";
  background: white;
}

h5 {
  color: dimgray;
}

.h-300 {
  width: 100%;
  height: 300px;
}

#map {
  height: 100%;
  margin: 0;
  padding: 0;
}

.scroll {
  overflow: auto;
  overflow-y: scroll;
}

.border-stripe {
  border-left: 9px solid !important;
}

.border-stripe h4 {
  font-size: 30pt;
  font-family: 'Barlow Condensed', sans-serif;
}

.border-stripe i {
  float: right;
  color: darkslategray;
}

.stripe-blue {
  border-color: #33b5e5 !important;
}

.stripe-blue h4 {
  color: #33b5e5 !important;
}

.stripe-green {
  border-color: hsl(143, 100%, 63%) !important;
}

.stripe-green h4 {
  color: hsl(143, 100%, 63%) !important;
}

.stripe-red {
  border-color: rgb(229, 72, 51) !important;
}

.stripe-red h4 {
  color: rgb(229, 72, 51) !important;
}

.stripe-dark {
  border-color: hsl(196, 42%, 15%) !important;
}

.stripe-dark h4 {
  color: hsl(196, 42%, 15%) !important;
}

.v2-container .table{
  text-align: left;
}

.v2-container .table tr td, .v2-container .table tr th{
  font-size: larger;
}
.App {

}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.loading-screen {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.add-person-loading-screen {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.App-link {
	color: #09d3ac;
}

.main-table {
	width: 100%;
	max-width: 800px;
	border: 1px solid red;
	margin-top: 30px;
	margin: 0 auto;
	border-collapse: collapse;
}

.main-table td,
.main-table th {
	border: 1px solid red;
}

.container {
	width: 100%;
	padding: 20px;
}

.login-form {
	max-width: 400px;
}

.border-bottom-gray {
	border-bottom: 1px solid #414141;
}
.fontsize-20 {
	font-size: 20px;
}
.text-orange {
	color: orange;
}
.text-pink {
	color: #ea3967bf;
}
.height-45 {
	height: 45px;
}
.height-30 {
	height: 30px;
}
.overflow-hidden {
	overflow: hidden;
}
.height-120 {
	height: 120px;
}
.text-red {
	color: red;
}
.font-weight-600 {
	font-weight: 600;
}
.font-size-15 {
	font-size: 15px;
}
.font-size-25 {
	font-size: 25px;
}
.font-size-30 {
	font-size: 30px;
}
.bg-gray {
	background: #41414140 !important;
}
#textarea-content {
	resize: none;
}
.create-notif-button {
	background-color: #53af31 !important;
	padding: 0px 30px !important;
	border-radius: 5px !important;
	height: 45px;
}
.br-5 {
	border-radius: 5px !important;
}
.text-white {
	color: #fff;
}
.save-button-color-blue {
	background-color: #3a7cabdb !important;
}
.bg-red {
	background-color: #c32c2cd6 !important;
}
::-webkit-input-placeholder {
	font-size: 12px;
	color: #41414190;
}
:-ms-input-placeholder {
	font-size: 12px;
	color: #41414190;
}
::-ms-input-placeholder {
	font-size: 12px;
	color: #41414190;
}
::placeholder {
	font-size: 12px;
	color: #41414190;
}
.font-size-12 {
	font-size: 12px;
}
.react-time-picker__wrapper {
	display: flex;
	border: thin solid gray;
	padding: 3px !important;
}
.note-text {
	font-size: 9px;
	line-height: 1;
	padding-left: 5px;
}

.bg-dark-blue{
  background: #5f819f !important;
}

.height-14{
  height: 14px;;
}

.font-size-20{
  font-size: 20px;
}

.color-gray{
  color: #cccc;
}

.custom-dropdown{
  background: transparent !important;
  box-shadow: none !important;
}

#drop.btn-secondary {
  color: #fff;
  background-color: transparent !important;
}

.psition-absolute{
	position: absolute;
}
.k-animation-container{
  position:absolute;
  overflow:hidden;
  z-index:1060 !important;
}
.k-select{
  display: none !important;
}
.line-height-2{
  line-height: 2.5 !important;
}
.k-time-cancel{
  display: none;
}
.rc-time-picker-clear-icon:after {
  display: none;
}

/* admin login */
.bg-login {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url(/static/media/admin-login-bg3.1e642bfe.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.card-container {
  width: 32rem;
  background-color: #3AAFA9 !important;
}

.admin-btn-login {
  background-color: #2B7A77 !important;
  text-transform: capitalize !important;
}

.border-landing{
  border-bottom: 1px solid;
}

.dxc-legend g g g text {
	fill: #fff;
}
.dxc-axes-group g g text {
	fill: #fff !important;
}

.background-image-project {
  background-color: #3AAFA9 !important;
}

/* border landing */
.borderLeftAdmitted {
	border-left: 9px solid #33b5e5 !important;
}

.borderRecovered {
	border-left: 9px solid #00c851 !important;
}

.borderConfirmed {
	border-left: 9px solid #ff3547 !important;
}

.borderDeaths {
	border-left: 9px solid #000 !important;
}

.totalPum:hover {
  border: 2px solid #33b5e5 !important;
}

.totalPui:hover {
  border: 2px solid #fdd66c !important;
}

.recoveryRate:hover{
  border: 2px solid #00c851 !important;
}

.deathRate:hover  {
	border: 2px solid #ff3547 !important;
}

.totalCases:hover {
	border: 2px solid #000 !important;
}

@media screen and (min-width: 1280px) {
  .landing .map {
    height: 120vh;
  }
}
