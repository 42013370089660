@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@200;300;500;600&display=swap);

.v2-container {
	font-family: "Barlow Condensed";
  background: white;
}

h5 {
  color: dimgray;
}

.h-300 {
  width: 100%;
  height: 300px;
}

#map {
  height: 100%;
  margin: 0;
  padding: 0;
}

.scroll {
  overflow: auto;
  overflow-y: scroll;
}

.border-stripe {
  border-left: 9px solid !important;
}

.border-stripe h4 {
  font-size: 30pt;
  font-family: 'Barlow Condensed', sans-serif;
}

.border-stripe i {
  float: right;
  color: darkslategray;
}

.stripe-blue {
  border-color: #33b5e5 !important;
}

.stripe-blue h4 {
  color: #33b5e5 !important;
}

.stripe-green {
  border-color: hsl(143, 100%, 63%) !important;
}

.stripe-green h4 {
  color: hsl(143, 100%, 63%) !important;
}

.stripe-red {
  border-color: rgb(229, 72, 51) !important;
}

.stripe-red h4 {
  color: rgb(229, 72, 51) !important;
}

.stripe-dark {
  border-color: hsl(196, 42%, 15%) !important;
}

.stripe-dark h4 {
  color: hsl(196, 42%, 15%) !important;
}

.v2-container .table{
  text-align: left;
}

.v2-container .table tr td, .v2-container .table tr th{
  font-size: larger;
}