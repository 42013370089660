.horizontal-scroll {
	width: 1300px;
	overflow-x: scroll;
	scrollbar-color: transparent;
}
.avatar-icon {
	height: 40px;
}
.avatar-icon-100 {
	height: 100px;
}
.border-black {
	border: 1px solid #000;
}
.height-150 {
	height: 150px;
}
.border-top-black {
	border-top: 1px solid #000;
}
.height-100 {
	height: 100px;
}
.overflow-scroll {
	overflow: auto;
}
.color-black {
	color: #000;
}
.display-none {
	display: none;
}
.hand {
	cursor: pointer;
}
.public-DraftStyleDefault-block {
	margin: 0 !important;
}
.rdw-emoji-modal {
	overflow: auto;
	position: absolute;
	top: 35px;
	left: -140px !important;
	right: 0px !important;
	display: flex;
	flex-wrap: wrap;
	width: 210px !important;
	height: 180px;
	border: 1px solid #f1f1f1;
	padding: 15px 0 15px 15px;
	border-radius: 2px;
	z-index: 100;
	background: white;
	box-shadow: 3px 3px 5px #bfbdbd;
}
::-webkit-scrollbar {
	width: 0;
	background: transparent;
}
.overflow-hidden {
	overflow: hidden;
}
.bg-green {
	background: #53af31 !important;
}
.width-120 {
	width: 120px;
}

.height-35 {
	height: 35px !important;
}
.bg-dblue {
	background: #5f819f !important;
}
.to-uppercase {
	text-transform: uppercase;
}
.w-100 {
	width: 100% !important;
}
