/* @import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@200;300;500;600&display=swap); */

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", "Barlow Condensed", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #3AAFA9 !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.card-large{
  height: 140px;
  color:#fff;
}

.text-huge{
  font-size: 3.5em;
}

.float{
	position:fixed;
	width:8vw;
	height:40px;
	bottom:40px;
	left:40px;
	background-color:#0C9;
	color:#FFF;
	border-radius:15px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.my-float{
  margin-top:7.5px;
}

.scrollable-table{
  height: 350px;
  overflow: auto;
  overflow-y: scroll;
}