.App {

}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.loading-screen {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.add-person-loading-screen {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.App-link {
	color: #09d3ac;
}

.main-table {
	width: 100%;
	max-width: 800px;
	border: 1px solid red;
	margin-top: 30px;
	margin: 0 auto;
	border-collapse: collapse;
}

.main-table td,
.main-table th {
	border: 1px solid red;
}

.container {
	width: 100%;
	padding: 20px;
}

.login-form {
	max-width: 400px;
}

.border-bottom-gray {
	border-bottom: 1px solid #414141;
}
.fontsize-20 {
	font-size: 20px;
}
.text-orange {
	color: orange;
}
.text-pink {
	color: #ea3967bf;
}
.height-45 {
	height: 45px;
}
.height-30 {
	height: 30px;
}
.overflow-hidden {
	overflow: hidden;
}
.height-120 {
	height: 120px;
}
.text-red {
	color: red;
}
.font-weight-600 {
	font-weight: 600;
}
.font-size-15 {
	font-size: 15px;
}
.font-size-25 {
	font-size: 25px;
}
.font-size-30 {
	font-size: 30px;
}
.bg-gray {
	background: #41414140 !important;
}
#textarea-content {
	resize: none;
}
.create-notif-button {
	background-color: #53af31 !important;
	padding: 0px 30px !important;
	border-radius: 5px !important;
	height: 45px;
}
.br-5 {
	border-radius: 5px !important;
}
.text-white {
	color: #fff;
}
.save-button-color-blue {
	background-color: #3a7cabdb !important;
}
.bg-red {
	background-color: #c32c2cd6 !important;
}
::placeholder {
	font-size: 12px;
	color: #41414190;
}
.font-size-12 {
	font-size: 12px;
}
.react-time-picker__wrapper {
	display: flex;
	border: thin solid gray;
	padding: 3px !important;
}
.note-text {
	font-size: 9px;
	line-height: 1;
	padding-left: 5px;
}

.bg-dark-blue{
  background: #5f819f !important;
}

.height-14{
  height: 14px;;
}

.font-size-20{
  font-size: 20px;
}

.color-gray{
  color: #cccc;
}

.custom-dropdown{
  background: transparent !important;
  box-shadow: none !important;
}

#drop.btn-secondary {
  color: #fff;
  background-color: transparent !important;
}

.psition-absolute{
	position: absolute;
}
.k-animation-container{
  position:absolute;
  overflow:hidden;
  z-index:1060 !important;
}
.k-select{
  display: none !important;
}
.line-height-2{
  line-height: 2.5 !important;
}
.k-time-cancel{
  display: none;
}
.rc-time-picker-clear-icon:after {
  display: none;
}

/* admin login */
.bg-login {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7)), url("../src/assets/images/admin-login/admin-login-bg3.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.card-container {
  width: 32rem;
  background-color: #3AAFA9 !important;
}

.admin-btn-login {
  background-color: #2B7A77 !important;
  text-transform: capitalize !important;
}

.border-landing{
  border-bottom: 1px solid;
}

.dxc-legend g g g text {
	fill: #fff;
}
.dxc-axes-group g g text {
	fill: #fff !important;
}

.background-image-project {
  background-color: #3AAFA9 !important;
}

/* border landing */
.borderLeftAdmitted {
	border-left: 9px solid #33b5e5 !important;
}

.borderRecovered {
	border-left: 9px solid #00c851 !important;
}

.borderConfirmed {
	border-left: 9px solid #ff3547 !important;
}

.borderDeaths {
	border-left: 9px solid #000 !important;
}

.totalPum:hover {
  border: 2px solid #33b5e5 !important;
}

.totalPui:hover {
  border: 2px solid #fdd66c !important;
}

.recoveryRate:hover{
  border: 2px solid #00c851 !important;
}

.deathRate:hover  {
	border: 2px solid #ff3547 !important;
}

.totalCases:hover {
	border: 2px solid #000 !important;
}

@media screen and (min-width: 1280px) {
  .landing .map {
    height: 120vh;
  }
}